<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/fontsource-pretendard@1.0.0-alpha.3/pretendard.css");
:root {
  --font-size-lg: max(2rem, min(4vw, 3.5rem));
  --font-size-md: max(1.5rem, min(3vw, 2.5rem));
  --font-size-sm: max(1rem, min(2vw, 1.5rem));
}
body {
  margin: 0px;
  font-family: "Pretendard";
}
</style>
