import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/view/HomeView.vue"),
  },
  {
    path: "/Intro",
    name: "intro",
    component: () => import("@/components/view/IntroView.vue"),
  },

  {
    path: "/service",
    name: "service",
    component: () => import("@/components/view/ServiceView.vue"),
  },
  {
    path: "/service/platform",
    name: "platform",
    component: () => import("@/components/view/ServiceView.vue"),
  },
  {
    path: "/way",
    name: "way",
    component: () => import("@/components/view/HomeView.vue"),
  },
  {
    path: "/news",
    name: "renewalNews",
    component: () => import("@/components/view/RenewalNewsView.vue"),
  },
  // {
  //   path: "/news",
  //   name: "news",
  //   component: () => import("@/components/view/NewsView.vue"),
  // },
  // {
  //   path: "/news/:id",
  //   name: "newsdetail",
  //   component: () => import("@/components/view/detail_news/NewsDetail.vue"),
  //   props: true,
  // },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/components/view/ContactView.vue"),
  },
  {
    path: "/employ",
    name: "employ",
    component: () => import("@/components/view/EmployView.vue"),
  },
  {
    path: "/header",
    name: "Header",
    component: () => import("@/components/header/HeaderNav.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/components/header/Menu.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/components/view/test.vue"),
  },

  {
    path: "/footer",
    name: "Footer",
    component: () => import("@/components/footer/Footer.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.path === "/way") {
      return { top: document.body.scrollHeight };
    }
    if (to.path === "/service/platform") {
      const service = document.querySelector("section");
      return { top: service.scrollHeight };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
