import { createStore } from "vuex";

const store = createStore({
  state: {
    isScrolling: false,
  },
  mutations: {
    setIsScrolling(state, value) {
      state.isScrolling = value;
    },
  },
  actions: {
    setIsScrolling({ commit }, value) {
      commit("setIsScrolling", value);
    },
  },
  getters: {
    isScrolling: (state) => state.isScrolling,
  },
});

export default store;
