const data = [
  {
    title: "부산IT교육센터 협약 체결",
    content:
      "2022년 10월 27일 (주)게임인스와 (주)지아이티아카데미,그린컴퓨터아카데미가 산업교육진흥 및 인력양성을 목적으로 산학협력을 체결하였습니다. 시장 경쟁력 강화 및 기술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다. 사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.",
    image: [require("@/assets/news/news8.svg")],
    isImageSmallFrame: true,
    date: "2022.10.27",
    category: "산학협력MOU",
  },
  {
    title: "2023 여성친화 일촌기업 협약",
    content:
      "사상여성새로일하기센터 협약을 통해(주)게임인스와 사상여성새로일하기센터의  보유한 자원과 역량을 공유하고, 상호 협력함으로써 시너지를 창출하고자 진행되었습니다.<br /><br /> 시장 경쟁력 강화 및 술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다.<br /><br /> 사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.<br />",
    image: [require("@/assets/news/news37.png")],
    isImageSmallFrame: true,
    date: "2023.06.22",
    category: "산학협력MOU",
  },
  {
    title: "2023 정보영 스피지 산학협력",
    content:
      "정보영 스피치와 산학협력 체결을 통해 (주)게임인스와 정보영스피치의  보유한 자원과 역량을 공유하고, 상호 협력함으로써 시너지를 창출하고자 진행되었습니다.<br /><br /> 시장 경쟁력 강화 및 술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다.<br /><br /> 사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.",
    image: [require("@/assets/news/news35.svg")],
    isImageSmallFrame: true,
    date: "2023.11.01",
    category: "산학협력MOU",
  },
  {
    title: "2023 사상여성인력개발센터 업무 협약",
    content:
      "(주)게임인스는 사상여성인력개발센터와 함께 여성 일자리 창출과 육아휴직 부담 없는 직장 환경 조성을 위한 업무 협약을 체결했습니다. 이번 협약을 통해 여성들이 안심하고 일할 수 있는 근무 환경을 제공하고, 일과 가정의 양립을 지원하는 다양한 제도를 마련할 예정입니다. 앞으로도 우리 회사는 지속 가능한 여성 친화적 일자리 창출을 위해 적극 노력하겠습니다.",
    image: [require("@/assets/news/news5.svg")],
    isImageSmallFrame: true,
    date: "2023.11.10",
    category: "산학협력MOU",
  },
  {
    title: "부산디자인진흥원 인력양성사업 협약",
    content:
      "부산디자인진흥원 협약을 통해 (주)게임인스와 부산디자인진흥원의  보유한 자원과 역량을 공유하고, 상호 협력함으로써 시너지를 창출하고자 진행되었습니다. 시장 경쟁력 강화 및 술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다.사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.",
    image: [
      require("@/assets/news/news19-1.svg"),
      require("@/assets/news/news19-2.svg"),
    ],
    isImageSmallFrame: true,
    date: "2022.04.04",
    category: "산학협력MOU",
  },
  {
    title: "센텀직업전문학교 업무 협약",
    content:
      "센텀직업전문학교 협약을 통해 (주)게임인스와 센텀직업전문학교의  보유한 자원과 역량을 공유하고, 상호 협력함으로써 시너지를 창출하고자 진행되었습니다.<br /><br />시장 경쟁력 강화 및 술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다. 사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.",
    image: [require("@/assets/news/news23.svg")],
    isImageSmallFrame: true,
    date: "2023.04.26",
    category: "산학협력MOU",
  },
  {
    title: "M아카데미컴퓨터아트학원 협력 체결",
    content:
      "M아카데미컴퓨터아트학원 협약을 통해 (주)게임인스와 M아카데미컴퓨터아트학원의  보유한 자원과 역량을 공유하고, 상호 협력함으로써 시너지를 창출하고자 진행되었습니다. 시장 경쟁력 강화 및 술 교류 및 개발을 하며 경영 노하우를 공유함으로써 기업의 경쟁력을 강화하고, 성장을 촉진 하고자 합니다. 사회적 책임을 이행하고, 지역사회와의 상생을 추구하는 (주)게임인스가 되겠습니다.",
    image: [require("@/assets/news/news22.svg")],
    isImageSmallFrame: true,
    date: "2023.04.12",
    category: "산학협력MOU",
  },
];

export default data;
