const data = [
  {
    title: "개인정보보호법 교육 수료",
    content:
      "(주)게임인스는 개인정보 보호의 중요성을 인식하고 철저히 준수할 수 있도록 개인정보보호법 교육을 수료하였습니다. 고객님의 소중한 개인정보를 안전하게 보호하기 위해 최선을 다하고 있습니다.",
    image: [require("@/assets/news/news4.png")],
    isImageSmallFrame: true,
    date: "2022.08.31",
    category: "교육이수",
  },
  {
    title: "2022 사무직 종사자의 안전관리 교육 이수",
    content:
      "(주)게임인스는 2022년 8기 사무직 종사자 안전관리 과정 교육을 이수하여, 모든 직원이 안전한 근무 환경에서 일할 수 있도록 노력하고 있습니다. 한국산업안전보건공단의 교육을 통해 사무직 근로자에게 필요한 안전 수칙과 예방 조치를 철저히 준수하고 있습니다. 앞으로도 직원들의 안전과 건강을 최우선으로 하여, 더욱 안전한 작업 환경을 만들기 위해 지속적으로 노력하겠습니다",
    image: [require("@/assets/news/news6.svg")],
    isImageSmallFrame: true,
    date: "2022.09.15",
    category: "교육이수",
  },
  {
    title: "사회적경제 창업 아카데미 교육 이수",
    content:
      "(주)게임인스는 2022년 북구 사회적경제 창업 아카데미 교육을 이수하여, 모든 직원이 안전한 근무 환경에서 일할 수 있도록 노력하고 있습니다. 한국산업안전보건공단의 교육을 통해 사무직 근로자에게 필요한 안전 수칙과 예방 조치를 철저히 준수하고 있습니다. 앞으로도 직원들의 안전과 건강을 최우선으로 하여, 더욱 안전한 작업 환경을 만들기 위해 지속적으로 노력하겠습니다",
    image: [require("@/assets/news/news11.png")],
    isImageSmallFrame: true,
    date: "2022.11.11",
    category: "교육이수",
  },
  {
    title: "대중문화예술기획업 등록 교육 이수",
    content:
      "(주)게임인스는 대중문화예술기획업 교육을 성공적으로 이수하였습니다. 이번 교육은 대중문화와 예술 산업의 최신 트렌드와 기획 역량을 강화하기 위해 진행되었으며, 참가자들은 기획, 마케팅, 콘텐츠 제작 등 다양한 분야의 전문 지식을 습득했습니다. 이를 통해 회사는 더욱 혁신적이고 창의적인 프로젝트를 선보일 수 있는 발판을 마련하게 되었습니다.",
    image: [require("@/assets/news/news30.png")],
    isImageSmallFrame: true,
    date: "2023.08.16",
    category: "교육이수",
  },
  {
    title: "한국건강가정진흥원 교육 이수",
    content:
      "(주)게임인스는 직원들의 가정 친화적 문화를 증진하기 위해 한국건강가정진흥원에서 주관하는 교육 프로그램을 이수했습니다. 이번 교육을 통해 가족 친화적 정책과 실천 방안을 학습하고, 건강한 가정과 일터를 만들기 위한 다양한 노하우를 습득하였습니다. 앞으로도 직원들의 일과 가정의 균형을 지원하기 위해 지속적으로 노력하겠습니다.",
    image: [require("@/assets/news/news25.png")],
    isImageSmallFrame: true,
    date: "2023.05.30",
    category: "교육이수",
  },
  {
    title: "1차 직무능력향상교육 이수",
    content:
      "(주)게임인스는 근로자의 직무 능력 향상을 위해 전문 교육 프로그램을 이수하였습니다. 이번 교육은 최신 산업 동향과 직무 관련 기술을 학습하여 직원들의 전문성을 강화하고, 업무 효율성을 높이기 위한 과정이었습니다. 이를 통해 우리 회사는 더욱 높은 품질의 서비스를 제공하고, 직원들의 경력 개발을 지원하며, 지속 가능한 성장을 이루어 나가겠습니다.",
    image: [require("@/assets/news/news14.svg")],
    isImageSmallFrame: true,
    date: "2023.01.17",
    category: "교육이수",
  },
  {
    title: "2차 직무능력향상교육 이수",
    content:
      "(주)게임인스는 근로자의 직무 능력 향상을 위해 전문 교육 프로그램을 이수하였습니다. 이번 교육은 최신 산업 동향과 직무 관련 기술을 학습하여 직원들의 전문성을 강화하고, 업무 효율성을 높이기 위한 과정이었습니다. 이를 통해 우리 회사는 더욱 높은 품질의 서비스를 제공하고, 직원들의 경력 개발을 지원하며, 지속 가능한 성장을 이루어 나가겠습니다.",
    image: [require("@/assets/news/news21.svg")],
    isImageSmallFrame: true,
    date: "2023.04.11",
    category: "교육이수",
  },
  {
    title: "소상공인 IP 역량강화 교육 수료",
    content:
      "부산지식재산센터에서 실시한 소상공인 IP 역량 강화 교육을 성공적으로 수료하였습니다. 이번 교육을 통해 지식재산의 중요성을 깊이 이해하고, 실무에 적용할 수 있는 다양한 전략과 노하우를 습득하게 되었습니다. 앞으로도 지식재산 보호와 활용을 통해 경쟁력을 강화하고, 지속 가능한 성장을 이루기 위해 노력하겠습니다.",
    image: [require("@/assets/news/news13.png")],
    isImageSmallFrame: true,
    date: "2022.11.24",
    category: "교육이수",
  },
];

export default data;
