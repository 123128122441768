import { createStore } from "vuex";
import certificateData from "./newsdata/certificateData";
import educationData from "./newsdata/educationData";
import mouData from "./newsdata/mouData";
import serviceData from "./newsdata/serviceData";
import workerData from "./newsdata/workerData";
import BtoGData from "./newsdata/BtoGData";
// 카테고리 리스트
const categories = [
  "기업인증",
  "교육이수",
  "산학협력MOU",
  "직접확인생산증명서",
  "근로자지원사업",
  "B to G",
];

// 더미 뉴스 데이터
const newsData = [
  ...certificateData,
  ...educationData,
  ...mouData,
  ...serviceData,
  ...workerData,
  ...BtoGData,
];

const newsStore = createStore({
  state: {
    menuNum: 0, // 선택된 메뉴 번호
    newsData: newsData,
    categories: categories,
  },
  mutations: {
    SET_CLICKED_NUM(state, value) {
      state.menuNum = value;
    },
    SET_CLICKED_CATEGORY(state, value) {
      state.menuNum = state.categories.indexOf(value);
    },
  },
  actions: {
    setClickedNum({ commit }, value) {
      commit("SET_CLICKED_NUM", value);
    },
    setClickedCategory({ commit }, value) {
      commit("SET_CLICKED_CATEGORY", value);
    },
  },
  getters: {
    selectedCategory: (state) => state.categories[state.menuNum],
    filteredNews: (state) => {
      return state.newsData
        .filter((news) => news.category === state.categories[state.menuNum])
        .sort((a, b) => {
          const dateA = new Date(a.date.replace(/\./g, "-")); // "2022.08.31" -> "2022-08-31"
          const dateB = new Date(b.date.replace(/\./g, "-"));
          return dateB - dateA; // 최신 날짜가 먼저 오도록 정렬
        });
    },

    mainFilteredNews: (state) => {
      return state.newsData
        .sort((a, b) => {
          const dateA = new Date(a.date.replace(/\./g, "-")); // "2022.08.31" -> "2022-08-31"
          const dateB = new Date(b.date.replace(/\./g, "-"));
          return dateB - dateA; // 최신 날짜가 먼저 오도록 정렬
        })
        .slice(0, 4);
    },
  },
});

export default newsStore;
