const data = [
  {
    title: "기타행사기획 및 대행서비스",
    content:
      "기타행사기획 및 대행서비스[물품분류번호:8014199001]는 공공기관 입찰, 수의계약, 나라장터 물품 등록(입찰참가자격등록)등의 목적으로 실제 행사제반운영(행사를 위한 인력 운영 및 관리,대관 등)  서비스가 가능함의 능력을 평가 받은 증명서입니다.",
    image: [
      require("@/assets/news/news40-1.svg"),
      require("@/assets/news/news40-2.svg"),
      require("@/assets/news/news40-3.svg"),
    ],
    isImageSmallFrame: true,
    date: "2024.02.19",
    category: "직접확인생산증명서",
  },
  {
    title: "정보시스템유지관리서비스",
    content:
      "정보시스템유지관리서비스[물품분류번호:8111189901]는 공공기관 입찰, 수의계약, 나라장터 물품 등록(입찰참가자격등록)등의 목적으로 공학연구및기술기반(시스템관리)서비스가 가능함의 능력을 평가 받은 증명서입니다.",
    image: [
      require("@/assets/news/news39-1.svg"),
      require("@/assets/news/news39-2.svg"),
      require("@/assets/news/news39-3.svg"),
    ],
    isImageSmallFrame: true,
    date: "2024.01.03",
    category: "직접확인생산증명서",
  },
  {
    title: "동영상제작서비스",
    content:
      "동영상제작서비스[물품분류번호:8213160301]는  공공기관 입찰, 수의계약, 나라장터 물품 등록(입찰 참가 자격 등록) 등의 목적으로 실제 동영상 제작 서비스가 가능함의 능력을 평가 받은 증명서입니다.",
    image: [
      require("@/assets/news/news31.png"),
      require("@/assets/news/news29.svg"),
    ],
    isImageSmallFrame: true,
    date: "2023.08.08",
    category: "직접확인생산증명서",
  },
];

export default data;
