const data = [
  {
    title: "근로자 휴가지원 사업 참여증서",
    content:
      "게임인스는 직장 내 자유로운 휴가문화 조성 및 국내 관광 활성화를 위하여 한국관광공사에서 시행중인 근로자 휴가지원사업의 2024년도 참여를 하였습니다.",
    image: [require("@/assets/news/news15.png")],
    isImageSmallFrame: true,
    date: "2023.02.02",
    category: "근로자지원사업",
  },
  {
    title: "청년내일채움공제 참여",
    content:
      "(주)게임인스는  청년내일채움공제 사업에 참여하여 청년들의 장기 근속을 지원하고, 안정적인 일자리 환경을 조성하기 위해 노력하고 있습니다. 청년내일채움공제는 중소·중견기업에 취업한 청년들의 자산 형성을 돕기 위한 정책으로, 일정 기간 동안 근속한 청년들에게 목돈을 마련할 수 있는 기회를 제공합니다.",
    image: [require("@/assets/news/news27.png")],
    isImageSmallFrame: false,
    date: "2022.08.31",
    category: "근로자지원사업",
  },
  {
    title: "2023 부산청년 파란일자리 지원사업 선정 및 협약",
    content:
      "'2023 부산청년 파란일자리 지원사업'에 선정되어 부산광역시와 협약을 체결하였습니다. 이번 협약을 통해 지역 청년들에게 양질의 일자리를 제공하고, 지속 가능한 성장을 위한 청년 인재 육성에 적극 동참하게 되었습니다. 앞으로도 청년들이 역량을 발휘할 수 있는 다양한 기회를 마련하여, 지역 경제 활성화와 청년 일자리 창출에 기여하는 선도 기업이 되겠습니다.",
    image: [require("@/assets/news/news18.svg")],
    isImageSmallFrame: true,
    date: "2023.03.01",
    category: "근로자지원사업",
  },
];

export default data;
