const data = [
  {
    title: "2024 부산교통공사축구단 홈경기 진행 위탁용역",
    content:
      "게임인스는 2024년 부산교통공사 축구단의 홈경기 진행을 위한 위탁 용역을 성공적으로 수행중에 있습니다. 본 프로젝트는 홈경기 운영 및 관리의 효율성을 극대화하고, 경기장을 방문하는 팬들에게 최상의 경험을 제공하기 위해 기획되었습니다. 경기장 내의 관람 환경 개선, 경기 진행의 원활한 운영, 그리고 경기 후 정리까지 모든 과정에서 철저한 관리와 높은 품질의 서비스를 제공하였습니다. 이를 통해 부산교통공사 축구단과의 긴밀한 협력 관계를 강화하고, 지역 스포츠 활성화에 기여하였습니다.",
    image: [
      require("@/assets/news/news50-1.svg"),
      require("@/assets/news/news50-2.svg"),
      require("@/assets/news/news50-3.svg"),
      require("@/assets/news/news50-4.svg"),
      require("@/assets/news/news50-5.svg"),
      require("@/assets/news/news50-6.svg"),
      require("@/assets/news/news50-7.svg"),
    ],
    isImageSmallFrame: false,
    date: "2024.08.31",
    category: "B to G",
  },
  {
    title: "메달리스트와 함께하는 경기컵 아마추어  e스포츠 대회",
    content:
      "게임인스는 '메달리스트와 함께하는 경기컵 아마추어 e스포츠 대회'를 성공적으로 개최하였습니다. 이 대회는 아마추어 e스포츠 선수들에게 실력을 겨룰 수 있는 무대를 제공하고, 게임에 대한 열정과 스포츠 정신을 고취시키기 위해 마련되었습니다. 유명 e스포츠 메달리스트들이 특별 게스트로 참석하여 참가자들과 함께 경기를 펼쳤으며, 이를 통해 경기의 흥미와 수준을 한층 더 높였습니다. 본 대회는 전국에서 수많은 참가자들의 호응을 받았으며, e스포츠의 저변 확대와 대중화에 크게 기여하였습니다.",
    image: [
      require("@/assets/news/news51-1.svg"),
      require("@/assets/news/news51-2.svg"),
      require("@/assets/news/news51-3.svg"),
      require("@/assets/news/news51-4.svg"),
      require("@/assets/news/news51-5.svg"),
      require("@/assets/news/news51-6.svg"),
      require("@/assets/news/news51-7.svg"),
    ],
    isImageSmallFrame: true,
    date: "2024.08.31",
    category: "B to G",
  },
  {
    title: "김해대학교 맞춤형 직무 역량 개선 교육",
    content:
      "게임인스는 24년 1월 김해대학교와 협력하여 맞춤형 직무역량 개선 교육 프로그램을 성공적으로 운영하였습니다. 대학 직무역량 모델에 따른 맞춤형 교육 연수 프로그램 지원을 통해 신입직원 교육 및 직무 재교육을 추진하였습니다. 공통역량 교육과 직급별 직무 역량 강화 교육 등 맞춤형 교육을 지원하였습니다.",
    image: [
      require("@/assets/news/news49-1.svg"),
      require("@/assets/news/news49-2.svg"),
      require("@/assets/news/news49-3.svg"),
      require("@/assets/news/news49-4.svg"),
      require("@/assets/news/news49-5.svg"),
      require("@/assets/news/news49-6.svg"),
      require("@/assets/news/news49-7.svg"),
      require("@/assets/news/news49-8.svg"),
      require("@/assets/news/news49-9.svg"),
    ],
    isImageSmallFrame: false,
    date: "2024.08.31",
    category: "B to G",
  },
];

export default data;
